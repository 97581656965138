
#sticky-wrapper.header {
    position: absolute;
    width: 100%;
    z-index: 90;

	@media (max-width: $screen-xs-max) {
		position: static;
	}
}
header.header {
    position: absolute;
    width: 100%;
    z-index: 90;

	@media (max-width: $screen-xs-max) {
		position: static;
	}
    .topbar {
        padding: 0;
        margin: 0;
        background: rgba($gray-light, 0.90);
        @include backdrop-filter(blur(7px));

        .inner {
            padding: 6px 15px 6px 15px;
            margin-right: 15px;
            text-align: right;
            font-weight: 400;

            .widget {
                font-size: $font-size-small;
                color: #fff;
                margin: 0;

				a {
					display: inline-block;
					margin: 0 0 0 15px;
					color: #fff;
					@include transition($transition-base);

					&:hover,
					&:hover i {
						color: $gray-darker;
					}
				}
				i {
					color: #fff;
				}
            }
        }
    }
    nav.navbar {
        margin-bottom: 0;
        background: rgba($white-smoke, 0.90);
		@include border-radius(0);
        @include box-shadow($box-shadow-base);
        @include backdrop-filter(blur(7px));

        .navbar-header {
            position: relative;

            .navbar-toggle {
                height: 54px;
                margin: 18px 0 0;
                padding: 15px 15px 15px 0;
                background-color: transparent;
                border: none;
                border-radius: 0;

                .icon-bar {
                    display: block;
                    width: 36px;
                    height: 4px;
                    border-radius: 4px;
                    background: $brand-secondary;
                    @include transition($transition-base);

                    &.middle {
                        visibility: hidden;
                        opacity: 0;
                    }
                    &.left {
                        @include transform(rotate(45deg));
                    }
                    &.right {
                        @include transform(rotate(-45deg));
                    }
                }
                .icon-bar + .icon-bar {
                    margin-top: -4px;
                }
                &.collapsed {

                    .icon-bar {
                        background: $brand-primary;
                        margin-top: 0;
                        @include transform(rotate(0));

                        &.middle {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    .icon-bar + .icon-bar {
                        margin-top: 6px;
                    }
                }
            }
            .navbar-brand {
                padding: ($grid-gutter-width / 2);
				padding-right: 0;

                & > img {
                    width: auto;
                    height: 100%;
                }
            }
        }
        .navbar-collapse {
            @include box-shadow(none !important);

            ul.navbar-nav {

                & > li {
                    display: block;
                    border-bottom: 1px solid $gray-lighter;

                    & > a {
                        font-weight: 900;
						font-size: $font-size-small;
                        font-family: $font-family-serif;
                        text-transform: uppercase;
                        text-shadow: 0 1px 0 rgba(#fff, 0.5);
                    }
                    &.active {

                        & > a {
							background: rgba($white-smoke, 0.75);
                        }
                    }
                    .dropdown-menu {
                        padding-left: 10px;
                        margin-bottom: ($grid-gutter-width / 2);

                        & > li {

                            & > a {
                                position: relative;
                                padding: 6px 6px 6px 26px;
                                font-weight: 400;
                                font-family: $font-family-sans-serif;
                                font-size: ceil($font-size-base * 0.9);
                                text-transform: uppercase;

                                &:before {
                                    content: '\f054';
                                    display: inline-block;
                                    position: absolute;
                                    left: 12px;
                                    top: 11px;
                                    color: $gray-dark;
                                    font: normal normal normal 11px/1 FontAwesome;
                                    text-rendering: auto;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    @include transition($transition-base);
                                }
                            }
                            &:hover {

                                & > a {

                                    &:before {
                                        left: 16px;
                                        color: $brand-primary;
                                    }
                                }
                                & > ul.dropdown-menu {
                                    display: block;
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                            & > ul.dropdown-menu {
                                top: 0;
                                left: 100%;
                                border-top: 0;
								padding-left: 0;
								padding-right: 0;

                                &:before {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    position: absolute;
                                    left: 0;
                                    top: 8px;
                                    margin-left: -8px;
                                    border-top: 8px solid transparent;
                                    border-bottom: 8px solid transparent;
                                    border-right: 8px solid $gray-darker;
                                }
                                & > li {

                                    & > a {
                                    }
                                }
                            }
                        }
                    }
                    &.dropdown {

                        a {

                            span.caret {
                                margin-top: 8px;
                                float: right;
                            }
                        }
                    }
                }
                @media (min-width: $grid-float-breakpoint) {
                    text-align: right;
                    float: none;
                    font-size: 0;

                    & > li {
                        width: auto;
                        display: inline-block;
                        float: none;

                        & > a {
                            font-size: $font-size-small;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: $grid-float-breakpoint) {

    header.header {

        nav.navbar {
			min-height: 70px;
			@include transition(min-height 0.4s ease);

			@media (min-width: $screen-lg-min) {
				min-height: 90px;

				.is-sticky & {
					min-height: 70px;
				}
			}
			.navbar-header {

				.navbar-brand {
					height: 70px;
					padding-top: 10px;
					padding-bottom: 10px;
					@include transition(height 0.4s ease);

					@media (min-width: $screen-lg-min) {
						height: 90px;

						.is-sticky & {
							height: 70px;
						}
					}
				}
			}
            .navbar-collapse {

                ul.navbar-nav {

                    & > li {
                        border-bottom: none;

                        & > a {
							padding: 24px 10px;
							font-size: ceil($font-size-small * 0.9);
							@include transition(all 0.4s ease);

							@media (min-width: $screen-lg-min) {
								padding: 34px 15px;
								font-size: $font-size-small;

								.is-sticky & {
									padding-top: 24px;
									padding-bottom: 24px;
								}
							}
                        }
                        &.open,
                        &.current-menu-item,
                        &.current-menu-parent {

                            & > a {
                                color: $brand-primary;
                            }
                        }
                        & > ul.dropdown-menu {
                            visibility: hidden;
                            opacity: 0;
                            display: block !important;
                            min-width: 220px;
                            right: auto;
                            left: 0;
                            padding: ($grid-gutter-width / 5) 0;
                            margin: 0;
                            border: none;
                            @include border-radius($border-radius-base);
                            @include box-shadow($box-shadow-base);
                            @include transition($transition-base);

                            & > li {

                                &.current-menu-item {

                                    & > a {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                        &.dropdown {

                            a {

                                span.caret {
                                    display: inline-block;
                                    width: 12px;
                                    height: 12px;
                                    position: absolute;
                                    left: 50%;
                                    bottom: 17%;
                                    float: none;
                                    margin-left: -6px;
                                    border: none;
                                    @include transition($transition-base);

                                    &:before {
                                        content: '\f078';
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        display: inline-block;
                                        color: $gray-light;
                                        font: normal normal normal 11px/1 FontAwesome;
                                        text-rendering: auto;
                                        -webkit-font-smoothing: antialiased;
                                        -moz-osx-font-smoothing: grayscale;
                                    }
                                }
                            }

                            &.open {

                                a {

                                    span.caret {
                                        bottom: 7%;

                                        &:before {
                                            color: $brand-primary;
                                        }
                                    }
                                }
                                & > ul.dropdown-menu {
                                    visibility: visible;
                                    opacity: 1;
                                    margin-top: 6px;
                                }
                            }
                        }
                        &:last-child {

                            & > ul.dropdown-menu {
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.slider {
	overflow: hidden;
	position: relative;
	width: 100%;

	#stripes {
		height: 100%;
		position: absolute;
		right: -17%;
		bottom: 0;
		z-index: 80;

		.stripe {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #E2000F;
			opacity: 0.5;
			@include transition($transition-base);
			@include animation(stripes 0.9s 1 backwards cubic-bezier(0.55, 0, 0.1, 1));

			&:hover {
				opacity: 0.65;
			}
			&#stripe-1 {
				@include animation-delay(0s);
			}
			&#stripe-2 {
				@include animation-delay(0.3s);
			}
			&#stripe-3 {
				@include animation-delay(0.6s);
			}
			@include keyframes(stripes) {
				from {
					@include transform(translateX(100%) rotate(0deg));
				}
				to {
					@include transform(translateX(0%) rotate(0deg));
				}
			};
		}
	}
	div.metaslider {
		overflow: hidden;
		border-bottom: 4px solid $brand-primary;

		.flexslider {
			margin-bottom: 0;
		}
		ol.flex-control-paging {
			bottom: 0;
			z-index: 20;

			li {

				a {
					display: inline-block;
					vertical-align: bottom;
					width: 8px;
					height: 12px;
					margin: 0 3px;
					background: $brand-primary;
					border: none;
					opacity: 0.6;
					@include border-radius(0);
					@include box-shadow(none);
					@include transition($transition-base);

					&.flex-active {
						height: 24px;
						opacity: 1;
					}
				}
			}
		}
		li.flex-active-slide {

			.caption {
				@include transform(translateX(0));
			}
		}
		.caption-wrap {
			opacity: 1;
			padding-top: 100px;
			@include linear-gradient(90deg, rgba($gray-darker, 1), rgba($gray-darker, 0));

			.caption {
				padding: $grid-gutter-width 0;
				@include transform(translateX(-100%));
				@include transition(all 1.2s cubic-bezier(0.55, 0, 0.1, 1));
				@include container-fixed;

				h1 {
					color: #fff;
				}
				@media (max-width: $screen-xs-max) {

					h1 {
						margin-top: 0;
						font-size: $font-size-h3;
					}
					h2 {
						margin-top: 0;
						font-size: $font-size-h4;
					}
				}
				@media (min-width: $screen-sm-min) {
					width: $container-sm;
				}
				@media (min-width: $screen-md-min) {
					width: $container-md;
				}
				@media (min-width: $screen-lg-min) {
					width: $container-lg;
				}
				* {
					color: #fff;
				}
			}
		}
	}
}
