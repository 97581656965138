
.sidebar {
    padding: ($grid-gutter-width * 2) 0;

    .widget {
        margin-bottom: 50px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                position: relative;
                display: block;

                a {
                    display: inline-block;
                    padding: 7px 0 7px 22px;
                    text-decoration: none;
                    color: $gray-dark;
                    @include transition($transition-base);

                    &:before {
                        content: '\f105';
                        display: inline-block;
                        position: absolute;
                        left: 8px;
                        top: 8px;
                        margin-right: 8px;
                        color: $gray;
                        font: normal normal normal $font-size-base/1 FontAwesome;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        @include transition($transition-base);
                    }
                    &:hover {
                        color: $brand-primary;

                        &:before {
                            left: 12px;
                            color: $brand-primary;
                        }
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
				&.current-menu-parent,
                &.current_page_item {

                    & > a {
                        color: $brand-primary;
                    }
					.sub-menu {
						display: block;
					}
                }
                .sub-menu {
					display: none;
                    padding-left: 0;

                    li {

                        a {
							font-size: $font-size-small;
                        }
                    }
                }
            }
			&.menu {

				li {

					a {

						&:before {
							display: none;
						}
					}
				}
			}
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.widget {
    margin-bottom: 40px;

    h4 {
		position: relative;
		overflow: hidden;
        padding: 6px 35px 6px 0;
        text-transform: uppercase;
        border-bottom: 3px solid lighten($brand-secondary, 40%);

		&:before,
		&:after {
			content: '';
			width: 5px;
			height: 50px;
			position: absolute;
			right: 10px;
			top: 0;
			background: $brand-primary;
			@include transform(skewX(-32deg));
			@include transform-origin(right top);
		}
		&:after {
			width: 7px;
			right: 0px;
		}
    }
    a {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0;

        i {
            position: relative;
            left: 0;
            color: $brand-secondary;
            @include transition($transition-base);
        }
        &:hover {

            i {
                left: 4px;
                color: $brand-primary;
            }
        }
    }
    i {
        min-width: 16px;
        margin-right: 3px;
        text-align: center;
    }
}
.widget_sp_image {
    overflow: hidden;
    margin: 0 0 $grid-gutter-width;

    img {
        max-width: 90% !important;
    }
}
.gksm-custom-menu-widget {

    ul.menu {
        overflow: hidden;
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 0;

        & > li {

            .footer-widgets & {
                display: block;
                border-bottom: 2px dashed lighten($brand-secondary, 40%);

                &:last-child {
                    border-bottom: none;
                }
            }
            .footer-line & {
                display: inline-block;

                & > a {
                    color: #fff;

                    &:hover {
                        color: $gray-dark;
                    }
                }
            }
            & > a {
                position: relative;
                display: block;
                padding: 7px 0 8px 0;
                margin: 0;
                font-size: $font-size-small;
                text-transform: uppercase;
                color: $gray-dark;
                @include transition($transition-base);

                &:hover,
                &:focus {
                    color: $brand-primary;
                    text-decoration: none;
                }
            }
        }
    }
    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
}
.wpcw-widget-social {
    @media (min-width: $screen-sm-min) {
        text-align: right;
    }
    @media (min-width: $screen-md-min) {
        text-align: left;
    }
}
