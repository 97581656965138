// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

// Colors
$brand-primary:         #E3000F;
$brand-secondary:       #7F7F7F;
$white-smoke:           #f5f5f4;

$box-shadow-base:       0 2px 5px 0 rgba(#000, 0.15);
$material:              cubic-bezier(0.55, 0, 0.1, 1);
$transition-base:       all 0.4s $material;

//
// Bootstrap Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13%);
$gray-dark:              lighten($gray-base, 20%);
$gray:                   lighten($gray-base, 34%);
$gray-light:             lighten($gray-base, 53%);
$gray-lighter:           lighten($gray-base, 94%);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            $gray;

//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      $gray-dark;
//** Link hover decoration.
$link-hover-decoration: none;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Ubuntu", sans-serif;
$font-family-serif:       "Orbitron", serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 3));
$font-size-small:         ceil(($font-size-base * 0.85));

$font-size-h1:            floor(($font-size-base * 2.4));
$font-size-h2:            floor(($font-size-base * 1.8));
$font-size-h3:            ceil(($font-size-base * 1.2));
$font-size-h4:            ceil(($font-size-base * 1));
$font-size-h5:            ceil(($font-size-base * 0.8));
$font-size-h6:            ceil(($font-size-base * 0.6));

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    $font-family-serif;
$headings-font-weight:    900;
$headings-line-height:    1.1;
$headings-color:          $brand-primary;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px;
$padding-base-horizontal:   12px;

$padding-large-vertical:    10px;
$padding-large-horizontal:  16px;

$padding-small-vertical:    5px;
$padding-small-horizontal:  10px;

$padding-xs-vertical:       1px;
$padding-xs-horizontal:     5px;

$line-height-large:         1.3333333;
$line-height-small:         1.5;

$border-radius-base:        4px;
$border-radius-large:       6px;
$border-radius-small:       3px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $brand-primary;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       transparent;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px;


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter;

//** Text color for `<input>`s
$input-color:                    $text-color;
//** `<input>` border color
$input-border:                   $gray-light;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    rgba($white-smoke, 0.9);
//** Dropdown menu `border-color`.
$dropdown-border:                transparent;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       transparent;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            $white-smoke;

//** Dropdown link text color.
$dropdown-link-color:            $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      $brand-primary;
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         $white-smoke;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           $gray-light;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-md-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    90px;
$navbar-margin-bottom:             $line-height-computed;
$navbar-border-radius:             $border-radius-base;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2));
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height:       340px;

$navbar-default-color:             $gray;
$navbar-default-bg:                $white-smoke;
$navbar-default-border:            transparent;

// Navbar links
$navbar-default-link-color:                $gray;
$navbar-default-link-hover-color:          $brand-primary;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $brand-primary;
$navbar-default-link-active-bg:            $white-smoke;

// Navbar toggle
$navbar-default-toggle-hover-bg:           $gray-lighter;
$navbar-default-toggle-icon-bar-bg:        $brand-primary;
$navbar-default-toggle-border-color:       $gray-light;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      $gray-lighter;
$navbar-inverse-bg:                         $gray-dark;
$navbar-inverse-border:                     $gray-dark;

// Inverted navbar links
$navbar-inverse-link-color:                 $gray-lighter;
$navbar-inverse-link-hover-color:           $brand-primary;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             $gray-dark;
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color:          $brand-primary;
$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            $gray-darker;
$navbar-inverse-toggle-icon-bar-bg:         $brand-primary;
$navbar-inverse-toggle-border-color:        $gray-dark;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px;
//** Thumbnail background color
$thumbnail-bg:                #fff;
//** Thumbnail border color
$thumbnail-border:            $gray-light;
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius-base;

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $text-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px;
