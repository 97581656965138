footer.footer {
	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: lighten($white-smoke, 3%);
		background-image: linear-gradient(45deg, $white-smoke 25%, transparent 25%, transparent 75%, $white-smoke 75%, $white-smoke),
		linear-gradient(45deg, $white-smoke 25%, transparent 25%, transparent 75%, $white-smoke 75%, $white-smoke);
		background-size: 50px 40px;
		background-position: 0 0, 25px 20px
	}
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include linear-gradient(-45deg, #fff, transparent, $white-smoke);
	}
    .footer-widgets {
		position: relative;
        padding: 40px 0 20px;
        font-size: 15px;
		z-index: 50;

        p {

            & > span.fa {
                color: $gray-darker;
            }
            & > a {
                color: $gray-darker;

                &:hover {
                    color: $brand-primary;
                }
            }
        }
        @media (min-width: $screen-sm-min) {
        }
        @media (min-width: $screen-md-min) {
        }
        @media (min-width: $screen-lg-min) {
        }
    }
    .footer-line {
        position: relative;
        padding: 13px 0 8px;
        color: $gray-dark;
        text-align: center;
        background: $white-smoke;
		z-index: 50;

        .footer-line-widget {

            .widget {
                margin: 0;

                ul.menu {

                    li {

                        a {
							margin-left: 15px;
                            font-size: ceil($font-size-small * 0.85);
                            color: $gray;

                            &:before {
                                top: 8px;
                                left: 10px;
                                font-size: $font-size-small;
                                color: $gray;
                            }
                            &:hover {
                                color: $brand-primary;

                                &:before {
                                    left: 14px;
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            position: relative;
            display: block;
            padding: 7px 0 8px;
            margin-bottom: 5px;
            font-family: $font-family-sans-serif;
            font-size: ceil($font-size-small * 0.85);
            text-transform: uppercase;
            color: $gray;
        }
        @media (min-width: $screen-md-min) {

            .footer-line-widget {
                text-align: right;
            }
            .copyright {
                text-align: left;
            }
        }
    }
}
