
.main {
	padding-top: 50px;
	padding-bottom: 50px;
}

.search-form {

	label {
		position: relative;
		max-width: 80%;
	}
	.search-submit {
		width: 36px;
		height: 36px;
		padding: 6px 0;
		color: #fff;
		background: $brand-primary;
		border-color: $brand-primary;
		outline: none;
		@include border-radius(50%);

		.fa {
			display: inline-block;
			position: relative;
			top: -1px;
			color: #fff;
			font-size: $font-size-base;
			@include transition($transition-base);
		}
		&:hover {
			background: lighten($brand-primary, 5%);
			border-color: $brand-primary;
		}
		&:active,
		&:focus,
		&:focus:active {
			background: darken($brand-primary, 5%);
			border-color: $brand-primary;
			outline: none;
		}
	}
	.search-field {
		width: 100%;
		padding: 6px 10px;
		border: 2px solid lighten($brand-secondary, 40%);
		outline: none;
		@include border-radius(2px);

		&:focus {
			outline: none;
			border-color: lighten($brand-primary, 40%);
			@include box-shadow(inset 0 3px 3px rgba(0,0,0,.075),0 0 8px rgba($brand-primary,.4));
		}
	}
}

.rennstreifen {
	background-color: lighten($brand-primary, 3%);
	background-image: linear-gradient(45deg, $brand-primary 25%, transparent 25%, transparent 75%, $brand-primary 75%, $brand-primary),
	linear-gradient(45deg, $brand-primary 25%, transparent 25%, transparent 75%, $brand-primary 75%, $brand-primary);
	background-size: 50px 40px;
	background-position: 0 0, 25px 20px
}
.overlay {
	background: $gray-darker;

	.vc_parallax-inner {
		opacity: 0.35;
	}
}
