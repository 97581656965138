// defining prefixes
$prefixes: ("-webkit-", "-moz-", "-ms-", "-o-", "" );

/*********************
TRANSITION
*********************/
// generic transition
@mixin transition($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transition: $value;
  }
}
// transition property
@mixin transition-property($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transition-property: $value;
  }
}
// transition duration
@mixin transition-duration($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transition-duration: $value;
  }
}
// transition timing-function
@mixin transition-timing-function($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transition-timing-function: $value;
  }
}
// transition delay
@mixin transition-delay($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transition-delay: $value;
  }
}

/*********************
TRANSFORM
*********************/
// generic transform
@mixin transform($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transform: $value;
  }
}
// transform origin
@mixin transform-origin($value...) {
  @each $prefix in $prefixes {
    #{$prefix}transform-origin: $value;
  }
}

/*********************
ANIMATION
*********************/
// generic animation
@mixin animation($value...) {
  @each $prefix in $prefixes {
    #{$prefix}animation: $value;
  }
}
// animation name
@mixin animation-name($value...) {
  @each $prefix in $prefixes {
    #{$prefix}animation-name: $value;
  }
}
// animation duration
@mixin animation-duration($value...) {
  @each $prefix in $prefixes {
    #{$prefix}animation-duration: $value;
  }
}
// animation fill mode
@mixin animation-fill-mode($value...) {
  @each $prefix in $prefixes {
    #{$prefix}animation-fill-mode: $value;
  }
}
// animation delay
@mixin animation-delay($value...) {
  @each $prefix in $prefixes {
    #{$prefix}animation-delay: $value;
  }
}
// keyframes
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

/*********************
BOX SIZING
*********************/
@mixin box-sizing($value...) {
  @each $prefix in $prefixes {
    #{$prefix}box-sizing: $value;
  }
}

/*********************
BOX SHADOW
*********************/
@mixin box-shadow($value...) {
  @each $prefix in $prefixes {
    #{$prefix}box-shadow: $value;
  }
}

/*********************
BORDER RADIUS
*********************/
@mixin border-radius($value...) {
  @each $prefix in $prefixes {
    #{$prefix}border-radius: $value;
  }
}

/*********************
BACKGROUND SIZE
*********************/
@mixin background-size($value...) {
  @each $prefix in $prefixes {
    #{$prefix}background-size: $value;
  }
}

/*********************
BACKDROP FILTER
*********************/
@mixin backdrop-filter($value...) {
  @each $prefix in $prefixes {
    #{$prefix}backdrop-filter: $value;
  }
}

/*********************
IMAGE RENDERING
*********************/
@mixin image-rendering ($mode:auto) {
  @if ($mode == crisp-edges) {
      -ms-interpolation-mode: nearest-neighbor; // IE8+
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
  }
  @else {
      image-rendering: $mode;
  }
}

/*********************
PLACEHOLDER COLOR
*********************/
@mixin input-placeholder($value) {
    &::-webkit-input-placeholder {
        color: $value;
    }
    &:-moz-placeholder {
        color: $value;
    }
    &::-moz-placeholder {
        color: $value;
    }
    &:-ms-input-placeholder {
        color: $value;
    }
}

/*********************
SELECTION
*********************/
@mixin selection($color, $background) {
    @each $prefix in $prefixes {
        ::#{$prefix}selection {
            color: $color;
            background: $background;
            text-shadow:none;
        }
    }
}

/*********************
CSS3 GRADIENTS
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

// gradient with angle
@mixin linear-gradient($angle, $color-stops...) {
    $_angle-with-vendor-prefix: "";
    $_angle: "";
    @if $angle == "to top" or $angle == "bottom" {
        $_angle-with-vendor-prefix: bottom;
        $_angle: to top;
    } @else if $angle == "to right" or $angle == "left" {
        $_angle-with-vendor-prefix: left;
        $_angle: to right;
    } @else if $angle == "to bottom" or $angle == "top" {
        $_angle-with-vendor-prefix: top;
        $_angle: to bottom;
    } @else if $angle == "to left" or $angle == "right" {
        $_angle-with-vendor-prefix: right;
        $_angle: to left;
    } @else if $angle == "to top right" or $angle == "bottom left" {
        $_angle-with-vendor-prefix: bottom left;
        $_angle: to top right;
    } @else if $angle == "to bottom right" or $angle == "top left" {
        $_angle-with-vendor-prefix: top left;
        $_angle: to bottom right;
    } @else if $angle == "to bottom left" or $angle == "top right" {
        $_angle-with-vendor-prefix: top right;
        $_angle: to bottom left;
    } @else if $angle == "to top left" or $angle == "bottom right" {
        $_angle-with-vendor-prefix: bottom right;
        $_angle: to top left;
    } @else {
        $_angle-with-vendor-prefix: $angle % 360;
        $_angle: (90 - $angle) % 360;
    }
    background: -webkit-linear-gradient($_angle-with-vendor-prefix, $color-stops);
    background: -moz-linear-gradient($_angle-with-vendor-prefix, $color-stops);
    background: -o-linear-gradient($_angle-with-vendor-prefix, $color-stops);
    background: linear-gradient($_angle, $color-stops);
}
